import styled from "@emotion/styled"
import { Button } from "rebass"

export default styled(Button)`
  cursor: pointer;
  color: #00003a;
  padding: 10px 30px;
  border: 1px solid #00003a;
  border-radius: 0;
  background-color: transparent;
`
