import React, { useEffect, useState } from "react"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import styled from "@emotion/styled"

import Spinner from "../ui/spinner"
import { Box } from "rebass"
import button from "../ui/button"
import StripePayment from "./pay-cards"

const Hr = styled(Box)`
  width: 80%;
  border-bottom: 1px solid #00003a11;
`

const PlaceOrderButton = styled(button)`
  width: 25%;
  min-width: 250px;
  background-color: transparent;
  border: 1px solid #00003a;
  color: #00003a;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events:none;
    color: #b1adad;
    border: 1px solid #b1adad;
  `}

  &:hover {
    text-decoration: underline;
  }
`

const PaymentStep = ({ active, onPaymentCompleted, shouldShow }) => {
  const [canPay, setCanPay] = useState(false)
  const [processing, setProcessing] = useState(false)

  const {
    cart,
    setPaymentSession,
    updatePaymentSession,
    processingPayment,
  } = useMedusaCheckout()

  useEffect(() => {
    if (active && !cart.payment_session) {
      setPaymentSession("stripe")
    }
  }, [active])

  const handleSetPaymentMethod = (method) => {
    return updatePaymentSession(method)
  }

  const handlePaymentCompleted = () => {
    if (onPaymentCompleted) {
      return onPaymentCompleted()
    }
  }

  return (
    <StripePayment
      cart={cart}
      session={cart.payment_sessions.find((s) => s.provider_id === "stripe")}
      setProcessing={setProcessing}
      onCanPay={setCanPay}
      onPaymentCompleted={handlePaymentCompleted}
      onPaymentMethodCreated={handleSetPaymentMethod}
      shouldShow={shouldShow}
    >
      <PlaceOrderButton type="submit" my={5} disabled={!canPay}>
        Place order
      </PlaceOrderButton>
      {(processing || processingPayment) && <Spinner />}
    </StripePayment>
  )
}

export default PaymentStep
