import React, { useReducer, useEffect } from "react"
import Medusa from "../../services/api"

export const defaultCheckoutContext = {
  adding: false,
  cart: { items: [], currencyCode: "DKK" },
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

export const MedusaCheckoutContext = React.createContext(defaultCheckoutContext)

const setCart = (cart) => {
  if (cart.email && cart.customer_id) {
    if (window && window.analytics) {
      window.analytics.identify(
        cart.customer_id,
        {
          email: cart.email,
        },
        {
          integrations: {
            MailChimp: false,
          },
        }
      )
    }
  }

  return {
    ...cart,
    tax_rate: cart.region.tax_rate,
    currency_code: cart.region.currency_code,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setErrors":
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      }
    case "setLoadingPaySessions":
      return {
        ...state,
        isLoadingPaySessions: action.payload,
      }
    case "setLoading":
      return {
        ...state,
        isLoading: false,
      }
    case "setCart":
      return {
        ...state,
        isLoading: false,
        cart: setCart(action.payload),
      }
    default:
      return state
  }
}

export const MedusaCheckoutProvider = ({ cartId, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultCheckoutContext)

  const refreshCart = async (id) => {
    dispatch({ type: "setLoading", payload: true })
    if (id) {
      return Medusa.cart.retrieve(id).then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
    }

    return Promise.resolve({})
  }

  const initializePaymentSessions = async (id) => {
    dispatch({ type: "setLoading", payload: true })
    return Medusa.cart
      .createPaymentSessions(id)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  useEffect(() => {
    if (cartId) {
      refreshCart(cartId).then(({ cart }) => {
        if (cart && !cart.payment_sessions?.length) {
          return initializePaymentSessions(cartId)
        }
      })
    }
  }, [cartId])

  const update = (payload) => {
    dispatch({ type: "setLoading", payload: true })
    return Medusa.cart
      .update(state.cart.id, payload)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const addShippingMethod = (payload) => {
    dispatch({ type: "setLoading", payload: true })
    return Medusa.cart
      .setShippingMethod(state.cart.id, payload)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const setPaymentSession = (provider) => {
    dispatch({ type: "setLoading", payload: true })
    return Medusa.cart
      .setPaymentSession(state.cart.id, provider)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const updatePaymentSession = (session) => {
    dispatch({ type: "setLoading", payload: true })
    return Medusa.cart
      .updatePaymentSession(state.cart.id, { session })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const getShippingOptions = () => {
    return Medusa.shippingOptions.list(state.cart.id).then(({ data }) => {
      const { shipping_options } = data

      return shipping_options.reduce((acc, next) => {
        if (acc[next.profile_id]) {
          acc[next.profile_id] = [...acc[next.profile_id], next]
        } else {
          acc[next.profile_id] = [next]
        }
        return acc
      }, {})
    })
  }

  const removeDiscount = (code) => {
    return Medusa.cart.discounts
      .delete(state.cart.id, code)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const removeLineItem = (lineId) => {
    return Medusa.cart.lineItems
      .delete(state.cart.id, lineId)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const updateLineItem = (lineId, quantity) => {
    return Medusa.cart.lineItems
      .update(state.cart.id, lineId, { quantity })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  return (
    <MedusaCheckoutContext.Provider
      value={{
        ...state,
        update,
        addShippingMethod,
        initializePaymentSessions,
        setPaymentSession,
        updatePaymentSession,
        getShippingOptions,
        removeDiscount,
        removeLineItem,
        updateLineItem,
      }}
    >
      {children}
    </MedusaCheckoutContext.Provider>
  )
}
