import styled from "@emotion/styled"
import { Elements } from "@stripe/react-stripe-js"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Box, Button, Flex, Image, Text } from "rebass"
import LSJLogo from "../../assets/images/lsj-logo.svg"
import getStripe from "../../utils/get-stripe"
import { formatPrices } from "../../utils/prices"
import Address from "../checkout/address"
import Delivery from "../checkout/delivery"
import PaymentStep from "../checkout/payment-step"
import Summary from "../checkout/summary"
import { useMedusaCheckout } from "../medusa-checkout-builder"
import Spinner from "../ui/spinner"

const Hr = styled(Box)`
  width: 80%;
  border-bottom: 1px solid #00003a11;
`

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: #eeece6;
  flex-direction: column;

  color: #00003a;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
`

const CheckoutFormContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 60%;

  @media only screen and (min-width: 901px) and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-top: 100px;
  }
`

const StyledImage = styled(Image)`
  margin-top: 40px;
  margin-left: 40px;
  cursor: pointer;
  max-height: 75px;
`

const PlaceOrderButton = styled(Button)`
  width: 25%;
  min-width: 250px;
  background-color: transparent;
  border: 1px solid #00003a;
  color: #00003a;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events:none;
    color: #b1adad;
    border: 1px solid #b1adad;
  `}

  &:hover {
    text-decoration: underline;
  }
`

const CartItemsContainer = styled(Flex)`
  max-height: 500px;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
`

const CartItem = styled(Flex)`
  flex-direction: row;
  flex-grow: 1;
`

const CheckoutPage = () => {
  const [stripe, setStripe] = useState(null)
  const [step, setStep] = useState("address")
  const [soldOutItems, setSoldOutItems] = useState(false)

  const {
    isLoading,
    cart,
    hasEmail,
    hasShippingAddress,
    hasShipping,
    update,
    addShippingMethod,
    processingShipping,
    processingDetails,
    processingPayment,
  } = useMedusaCheckout()

  useEffect(() => {
    switch (true) {
      case hasEmail && hasShippingAddress && hasShipping:
        setStep("payment")
        break
      case hasEmail && hasShippingAddress:
        setStep("delivery")
        break
      default:
        setStep("address")
        break
    }
  }, [cart, hasEmail, hasShipping, hasShipping])

  const showSpinner = isLoading || !cart.payment_sessions

  const handleSetShippingMethod = async (selectedShippingMethods) => {
    return Promise.all(
      selectedShippingMethods.map((option) => {
        return addShippingMethod({
          option_id: option.id,
          data: option.data,
        })
      })
    )
  }

  const handleDeliverySelected = () => {
    console.log("d")
  }

  const removeNullish = (obj) => {
    return Object.entries(obj).reduce((acc, [key, val]) => {
      if (val) {
        acc[key] = val
      }
      return acc
    }, {})
  }

  const handleAddressSelected = (data) => {
    const shipping_address = removeNullish({
      first_name: data.first_name,
      last_name: data.last_name,
      address_1: data.address_1,
      address_2: data.address_2,
      city: data.city,
      province: data.province,
      postal_code: data.postal_code,
      country_code: data.country_code,
      phone: data.phone,
    })

    return update({ email: data.email, shipping_address })
  }

  const handleEmailUpdate = async (email) => {
    return update({ email })
  }

  /**
   * If payment is completed by the underlying component in payment state
   * go straight to the payment site
   */
  const handlePaymentCompleted = async () => {
    navigate("/checkout/payment")
  }

  return (
    <Container>
      <Helmet title={"Checkout | LSJ"} />
      {cart && !showSpinner && (
        <Flex width="100%">
          <StyledImage src={LSJLogo} onClick={() => navigate(`/`)} />
        </Flex>
      )}
      <Flex justifyContent="center">
        <CheckoutFormContainer mb={5} height={"100%"}>
          {!cart || showSpinner ? (
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={5}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              <Flex flexDirection="column" width="80%" alignItems="center">
                {soldOutItems && (
                  <>
                    <Text mb={4}>
                      Some of your items were sold out - sorry! Please try again
                    </Text>
                  </>
                )}
                <Text mb={4}>Order summary</Text>
                <CartItemsContainer>
                  {cart.items.map((item, i) => {
                    const { variant } = item

                    const price = formatPrices(cart, item.unit_price)

                    return (
                      <CartItem key={i} mb={2}>
                        <Image src={item.thumbnail} maxHeight="100px" />
                        <Flex flexDirection="column" ml={4}>
                          <Text fontSize={1}>{variant.product.title}</Text>
                          <Text fontSize={1}>x {item.quantity}</Text>
                          <Text fontSize={1}>{price}</Text>
                        </Flex>
                      </CartItem>
                    )
                  })}
                </CartItemsContainer>
              </Flex>
              <Hr my={5} />
              <Address
                active={step === "address"}
                cart={cart}
                onEdit={() => setStep("address")}
                onComplete={handleAddressSelected}
                handleEmailUpdate={handleEmailUpdate}
                processing={processingDetails}
              />
              <Hr my={5} />

              <Delivery
                active={step === "delivery"}
                hasShippingAddress={hasShippingAddress}
                cart={cart}
                onComplete={handleDeliverySelected}
                selectedOptions={cart.shipping_methods}
                handleSetShippingMethod={handleSetShippingMethod}
              />
              <Hr my={5} />
              {processingPayment ? (
                <Spinner />
              ) : (
                <Elements stripe={getStripe()}>
                  <PaymentStep
                    shouldShow={hasShipping && hasShippingAddress}
                    onPaymentCompleted={handlePaymentCompleted}
                    active={step === "payment"}
                  />
                </Elements>
              )}
              <Hr my={5} />
              {processingShipping ? <Spinner /> : <Summary cart={cart} />}
            </>
          )}
        </CheckoutFormContainer>
      </Flex>
    </Container>
  )
}

export default CheckoutPage
