import React, { useContext, useState, useEffect } from "react"

import { MedusaCheckoutContext } from "./checkout-provider"

export const useMedusaCheckout = () => {
  const [loadingSessions, setLoadingSessions] = useState(false)

  const [processingDetails, setProcessingDetails] = useState(false)
  const [processingShipping, setProcessingShipping] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)

  const [paymentProviders, setPaymentProviders] = useState([])
  const [hasEmail, toggleEmail] = useState(false)
  const [hasBillingAddress, toggleBillingAddress] = useState(false)
  const [hasShippingAddress, toggleShippingAddress] = useState(false)
  const [hasShipping, toggleShipping] = useState(false)

  const {
    isLoading,
    cart,
    update,
    errors,
    addShippingMethod,
    setPaymentSession,
    updatePaymentSession,
    getShippingOptions,
    updateLineItem,
    removeLineItem,
    removeDiscount,
  } = useContext(MedusaCheckoutContext)

  const handleShippingMethod = (payload) => {
    setProcessingShipping(true)
    return addShippingMethod(payload).finally(() => {
      setProcessingShipping(false)
    })
  }

  const handleUpdate = (payload) => {
    setProcessingDetails(true)
    return update(payload)
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setProcessingDetails(false)
      })
  }

  const handleUpdatePaymentSession = (session) => {
    setProcessingPayment(true)
    return updatePaymentSession(session).finally(() => {
      setProcessingPayment(false)
    })
  }

  useEffect(() => {
    toggleEmail(!!cart.email)
  }, [cart.email])

  useEffect(() => {
    const { shipping_address } = cart
    toggleShippingAddress(
      shipping_address &&
        !!shipping_address.first_name &&
        !!shipping_address.last_name &&
        !!shipping_address.address_1 &&
        !!shipping_address.postal_code &&
        !!shipping_address.country_code &&
        !!shipping_address.phone
    )
  }, [cart.shipping_address])

  useEffect(() => {
    const { billing_address } = cart
    toggleBillingAddress(
      billing_address &&
        !!billing_address.first_name &&
        !!billing_address.last_name &&
        !!billing_address.address_1 &&
        !!billing_address.postal_code &&
        !!billing_address.country_code
    )
  }, [cart.billing_address])

  // Populates convenience variables
  useEffect(() => {
    const { payment_sessions } = cart
    setPaymentProviders(payment_sessions?.map((s) => s.provider_id) || [])
  }, [cart.payment_sessions])

  useEffect(() => {
    const { items } = cart
    toggleShipping(items.every((i) => i.has_shipping))
  }, [cart.items])

  return {
    isLoading,
    cart,
    errors: errors || [],

    // Convenience
    hasEmail,
    hasBillingAddress,
    hasShippingAddress,
    hasShipping,
    paymentSession: cart.payment_session,

    processingPayment,
    processingShipping,
    processingDetails,

    // Modifiers
    update: handleUpdate,
    updateLineItem,
    removeLineItem,
    setPaymentSession,
    updatePaymentSession: handleUpdatePaymentSession,
    paymentProviders,
    addShippingMethod: handleShippingMethod,
    removeDiscount,

    // Getters
    getShippingOptions,
  }
}
