import React, { useState } from "react"
import styled from "@emotion/styled"
import { Input as RebassInput } from "@rebass/forms"

export const StyledInput = styled(RebassInput)`
  border: none;
  border-bottom: 1px solid #b1adad;
  border-top: none;
  border-left: none;
  border-right: none;

  &::placeholder {
    color: #00003a;
    opacity: 0.3;
  }

  &:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
  }

  // Disabled Chrome's autofill color
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  color: #00003a !important;
  &:focus {
    color: #00003a !important;
  }
`

export const Input = (props) => {
  const [value, setValue] = useState(props.value || props.defaultValue || "")

  const onChange = (e) => {
    props.onChange && props.onChange(e)
    setValue(e.target.value)
  }

  return <StyledInput value={value} {...props} onChange={onChange} />
}
