import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { useForm } from "react-hook-form"
import { Button, Flex, Text } from "rebass"
import { StyledInput as Input } from "../ui/input"

import {
  currencyCodeToSymbol,
  formatPrices,
  formatShipping,
} from "../../utils/prices"
import Medusa from "../../services/api"

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`

const ShippingOption = styled(Flex)`
  cursor: pointer;

  margin: 10px auto;
  padding: 10px;
  width: 80%;
  border: 1px solid #00003a;
  justify-content: space-between;

  ${(props) => props.selected && "font-weight: bold;"}

  &:hover {
    font-weight: bold;
  }
`

const Delivery = ({
  cart,
  selectedOptions,
  setSelectedOptions,
  handleSetShippingMethod,
  hasShippingAddress,
}) => {
  const [shippingOptions, setShippingOptions] = useState([])

  const fetchShippingOptions = async () => {
    await Medusa.shippingOptions.list(cart.id).then(({ data }) => {
      const { shipping_options } = data

      const partitioned = shipping_options.reduce((acc, next) => {
        if (acc[next.profile_id]) {
          acc[next.profile_id] = [...acc[next.profile_id], next]
        } else {
          acc[next.profile_id] = [next]
        }
        return acc
      }, {})

      Object.keys(partitioned).forEach(async (k) => {
        //if (partitioned[k].length === 1) {
        //  // Preselect the option if there is only one
        //  setSelectedOptions({
        //    ...selectedOptions,
        //    [k]: partitioned[k][0],
        //  })
        //}

        handleSetShippingMethod([partitioned[k][0]])
      })

      setShippingOptions(partitioned)
    })
  }

  useEffect(() => {
    // Fetch shipping options
    fetchShippingOptions()
  }, [cart.region.id])

  const handleSelectOption = (k, o) => {
    const selectedOptions = [o]
    // setSelectedOptions(selectedOptions)
    handleSetShippingMethod(selectedOptions)
  }

  return (
    <Container>
      <Flex mb={4}>Shipping</Flex>
      {hasShippingAddress && (
        <Flex flexDirection="column" width="100%">
          {Object.keys(shippingOptions).map((k, i) => {
            const options = shippingOptions[k]
            const selectedOption = selectedOptions[0] || {}

            return (
              <Flex flexDirection="column" key={i} width="100%">
                {options.map((o, i2) => (
                  <ShippingOption
                    key={i2}
                    selected={o.id === selectedOption.shipping_option_id}
                    onClick={() => handleSelectOption(k, o)}
                  >
                    <Flex>{o.name}</Flex>
                    <Flex>{formatShipping(o.amount, cart)}</Flex>
                  </ShippingOption>
                ))}
              </Flex>
            )
          })}
        </Flex>
      )}
    </Container>
  )
}

export default Delivery
