import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { Flex, Text } from "rebass"

const CheckoutForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
  min-height: 50px;

  .StripeElement {
    width: 100%;
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;

    &::placeholder {
      font-family: Georgia;
      font-style: normal;
      font-weight: normal;
    }
  }
`
const CardContainer = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid #b1adad;
`

const CardElementStyle = {
  base: {
    fontFamily: "Georgia",
    fontSize: "16px",
    color: "#00003a",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#9e2146",
  },
}

const StripePayment = ({
  session,
  children,
  cart,
  setProcessing,
  onPaymentCompleted,
  onCanPay,
  shouldShow,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleChange = async (e) => {
    onCanPay(e.complete)
  }

  const handlePayment = async (e) => {
    e.preventDefault()

    if (e.completed) {
    }

    if (!stripe || !elements) {
      return
    }

    setProcessing(true)

    const { client_secret } = session.data
    const email = cart.email
    const address = cart.shipping_address

    return stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: address.fullName,
            email: email,
            phone: address.phone,
            address: {
              city: address.city,
              country: address.country,
              line1: address.line1,
              line2: address.line2,
              postal_code: address.postal,
            },
          },
        },
      })
      .then(({ error, paymentIntent }) => {
        if (error) {
          const pi = error.payment_intent

          if (
            (pi && pi.status === "requires_capture") ||
            (pi && pi.status === "succeeded")
          ) {
            return onPaymentCompleted()
          }

          setErrorMessage(error.message)
          return
        }

        if (
          (paymentIntent && paymentIntent.status === "requires_capture") ||
          paymentIntent.status === "succeeded"
        ) {
          return onPaymentCompleted()
        }

        return
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <Container>
      <CheckoutForm onSubmit={handlePayment}>
        <Text mb={4}>Payment details</Text>
        {errorMessage && (
          <Text fontStyle="italic" mb={3}>
            Sorry we failed to process your payment — please try again or use a
            different card.
          </Text>
        )}
        {shouldShow && (
          <CardContainer p={3}>
            <CardElement
              onChange={handleChange}
              options={{
                hidePostalCode: true,
                style: CardElementStyle,
              }}
            />
          </CardContainer>
        )}
        {children}
      </CheckoutForm>
    </Container>
  )
}

export default StripePayment
