import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import { Text, Flex, Box } from "rebass"
import { StyledInput as Input } from "../ui/input"
import Button from "../ui/button"
import { countryLookup } from "../../utils/countries"
import Spinner from "../ui/spinner"

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
`

const Select = styled.select`
  flex: 1;
  // width: 100px;
  // margin-bottom: 30px;
  margin-top: 10px;
  background-color: #eeece6;
  outline: none;
  border: 1px solid #00003a;
`

const StyledButton = styled(Button)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 60px;
  // font-size: 12px;
`

const Address = ({ active, cart, onEdit, onComplete, processing }) => {
  const { register, handleSubmit, reset } = useForm()

  useEffect(() => {
    reset({
      email: cart.email,
      ...cart.shipping_address,
    })
  }, [cart.shipping_address, cart.email])

  const onSubmit = (data) => {
    const d = data

    // Send the country if there is only one in the region
    if (cart.shipping_address.country_code) {
      d.country_code = cart.shipping_address.country_code
    }

    if (onComplete) {
      onComplete(d)
    }
  }

  const handleWrongCountry = () => {
    navigate("/?region_pick=true")
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Text mb={4}>Shipping Address</Text>
      {processing ? (
        <Flex height="100%">
          <Spinner />
        </Flex>
      ) : active ? (
        <>
          <Flex width="100%" mb={4}>
            <Flex px={2} width="50%" flexDirection="column">
              <Text mr={3}>
                Shipping to:{" "}
                {countryLookup(
                  cart.shipping_address.country_code
                ).toUpperCase()}
              </Text>
              <Text
                onClick={handleWrongCountry}
                sx={{
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                fontStyle="italic"
                fontSize={0}
                mr={3}
              >
                If this is not your country please click here
              </Text>
            </Flex>
          </Flex>
          <Flex width="100%">
            <Input
              placeholder="E-mail"
              name="email"
              ref={register({ required: true })}
            />
          </Flex>
          <Flex width="100%">
            <Input
              placeholder="First name"
              name="first_name"
              mr={3}
              ref={register({ required: true })}
            />
            <Input
              placeholder="Last name"
              name="last_name"
              ref={register({ required: true })}
              ml={3}
            />
          </Flex>
          <Input
            placeholder="Address line 1"
            name="address_1"
            ref={register({ required: true })}
          />
          <Input placeholder="Address line 2" name="address_2" ref={register} />
          <Flex width="100%">
            <Input
              placeholder="City"
              name="city"
              ref={register({ required: true })}
              mr={3}
            />
            <Input
              placeholder="Postal code"
              name="postal_code"
              ref={register({ required: true })}
              ml={3}
            />
          </Flex>
          <Input
            placeholder="Phone"
            name="phone"
            ref={register({ required: true })}
          />
          <Flex mt={4}>
            <Button type="submit">Next</Button>
          </Flex>
        </>
      ) : cart.shipping_address && cart.shipping_address.first_name ? (
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Box>
            <Text>
              {cart.shipping_address.first_name}{" "}
              {cart.shipping_address.last_name}
            </Text>
            <Text>
              {cart.shipping_address.address_1}{" "}
              {cart.shipping_address.address_2 || ""},{" "}
              {cart.shipping_address.postal_code} {cart.shipping_address.city}
            </Text>
            <Text>
              {countryLookup(cart.shipping_address.country_code.toUpperCase())}
            </Text>
          </Box>
          <StyledButton pl="10px" pr="10px" onClick={onEdit}>
            Edit
          </StyledButton>
        </Flex>
      ) : (
        <Text>Address</Text>
      )}
    </Container>
  )
}

export default Address
