import React, { useState, useEffect } from "react"
import _ from "lodash"

import Checkout from "../../components/checkout"
import { MedusaCheckoutProvider } from "../../components/medusa-checkout-builder"

const CheckoutPage = () => {
  const [cartId, setCartId] = useState(null)

  useEffect(() => {
    const cachedCart = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(cachedCart)
    setCartId(cart.id)
  }, [])

  return (
    <MedusaCheckoutProvider cartId={cartId}>
      <Checkout />
    </MedusaCheckoutProvider>
  )
}

export default CheckoutPage
