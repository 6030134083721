import React from "react"
import styled from "@emotion/styled"
import { Box, Flex, Text } from "rebass"
import {
  currencyCodeToSymbol,
  formatCartDiscount,
  formatCartSubtotal,
  formatCartShippingTotal,
  formatVatDeduction,
  formatCartTotal,
} from "../../utils/prices"

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
`

const Summary = (props) => {
  const { cart } = props

  return (
    <Container>
      {cart && (
        <>
          <Flex width="100%" mb={1}>
            <Text>Subtotal</Text>
            <Box ml="auto" />
            <Text>
              {formatCartSubtotal(cart)}{" "}
              {/* {currencyCodeToSymbol(cart.currencyCode)} */}
            </Text>
          </Flex>
          {!!cart.discountTotal && (
            <Flex width="100%" mb={1}>
              <Text>
                Discount:{" "}
                <i
                  // onClick={() => onRemoveCouponCode(cart.discounts[0].code)}
                  className="fal fa-times"
                />
                {cart.discounts[0].code}
              </Text>
              <Box ml="auto" />
              <Text>{formatCartDiscount(cart)}</Text>
            </Flex>
          )}
          <Flex width="100%" my={1}>
            <Text>Shipping</Text>
            <Box ml="auto" />
            <Text>
              {formatCartShippingTotal(cart)}{" "}
              {/* {currencyCodeToSymbol(cart.currencyCode)} */}
            </Text>
          </Flex>
          <Flex width="100%" my={1}>
            <Text>Total</Text>
            <Box ml="auto" />
            <Text>{formatCartTotal(cart)}</Text>
          </Flex>
          <Flex width="100%" my={1} mb={4}>
            <Text></Text>
            <Box ml="auto" />
            <Text sx={{ fontStyle: "italic" }} fontSize={0}>
              Incl. VAT of {formatVatDeduction(cart)}
              {/* {currencyCodeToSymbol(cart.currencyCode)} */}
            </Text>
          </Flex>
        </>
      )}
    </Container>
  )
}

export default Summary
